import { render, useEffect, useRef } from '@wordpress/element';

import './style.scss';
import Style from './Style';
import { controlsHandler } from './Const/functions';

// Video Player
document.addEventListener('DOMContentLoaded', () => {
	const allVideoPlayer = document.querySelectorAll('.wp-block-vpb-video');
	allVideoPlayer.forEach(videoPlayer => {
		const attributes = JSON.parse(videoPlayer.dataset.attributes);

		render(<>
			<Style attributes={attributes} clientId={attributes.clientId} />

			<RenderVideo attributes={attributes} />
		</>, videoPlayer);

		videoPlayer?.removeAttribute('data-attributes')
	});
});

const RenderVideo = ({ attributes }) => {
	const { source, poster, controls, repeat, autoplay, muted, resetOnEnd, autoHideControl } = attributes;

	const videoEl = useRef(null);

	useEffect(() => {
		new Plyr(videoEl.current, {
			controls: controlsHandler(controls),
			clickToPlay: false,
			loop: { active: repeat },
			muted,
			autoplay,
			resetOnEnd,
			hideControls: autoHideControl,
		});
	}, []);

	return <div className='vpbVideoPlayer'>
		<div className='videoWrapper'>
			<video controls playsinline data-poster={poster} preload='metadata' ref={videoEl}>
				Your browser does not support the video tag.
				<source src={source} type='video/mp4' />
			</video>
		</div>
	</div>
} 